import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

export const postFetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  console.log(url, config);

  const res = await axiosInstance.post(url, { ...config }, { withCredentials: true });

  return res;
};

export const sendMail = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  console.log(url, config);

  const res = await axiosInstance.post(url, { ...config }, { withCredentials: true });

  return res;
};

export const addDraft = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  console.log(url, config);

  const res = await axiosInstance.post(url, { ...config });

  return res.status;
};

export const sendMailAnswer = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  console.log(url, config);

  const res = await axiosInstance.post(url, { ...config }, { withCredentials: true });

  return res;
};

// ADMIN
export const deleteMail = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  console.log(url, config);

  const res = await axiosInstance.post(url, { ...config });

  // const res = {status: 200}
  return res.status;
};

export const deleteDraft = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  console.log(url, config);

  const res = await axiosInstance.post(url, { ...config });

  // const res = {status: 200}
  return res.status;
};

export const updateMail = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  console.log(url, config);

  const res = await axiosInstance.post(url, { ...config });
  // const res = {status: 200}
  return res.status;
};

export const updateDraft = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.put(url, { ...config });
  // const res = {status: 200}
  return res.status;
};

export const updateMailRead = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];
  const res = await axiosInstance.put(url, { ...config }, { withCredentials: true });
  return res.status;
};

export const archiveMail = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.put(url, { ...config });
  // const res = {status: 200}
  return res.status;
};

// chatgpt
export const sendAnswerChatGpt = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.post(url, { ...config });

  return res;
};

export const sendResumeChatGpt = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.post(url, { ...config });

  return res;
};

export const getAttachment = async (args) => {
  const url = args;

  const res = await axiosInstance.get(url, {
    responseType: 'blob',
  });

  return res;
};

export const addCategory = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  console.log(url, config);

  const res = await axiosInstance.post(url, { ...config }, { withCredentials: true });

  return res;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
    googleLogin: '/api/auth/google',
  },
  mail: {
    list: '/api/mail/list',
    threads: 'api/mails/threads',
    details: '/api/mails/details',
    labels: '/api/mails/labels',
    addmail: '/api/mails/addMail',
    addanswer: '/api/mails/addAnswer',
    forward: '/api/mails/forward',
    deletemail: '/api/mail/deleteMail',
    updatemail: '/api/mail/updateMail',
    updateasread: '/api/mails/setAsRead',
    archivemail: '/api/mail/archiveMail',
    adddraft: '/api/drafts/adddraft',
    updatedraft: '/api/drafts/updatedraft',
    deletedraft: '/api/drafts/deleteDraft',
    singleAttachment: '/api/mails/attachment',
  },
  search: {
    global: '/api/mails/search_global',
  },
  users: {
    addcategory: '/api/users/addCategory',
    generatecategories: '/api/users/generateCategories',
  },
  chatgpt: {
    getanswer: '/api/gpt/answer',
    getresume: '/api/gpt/resume',
    generatecategories: '/api/ai/generateCategories',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};
