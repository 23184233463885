import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';

// utils
import axios, { endpoints } from 'src/utils/axios';
import { useRouter } from 'src/routes/hooks';
//
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  from: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    console.log(action.payload);
    return {
      ...state,
      user: action.payload.user,
      from: action.payload.from,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
      from: action.payload.from,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
      from: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const router = useRouter();

  const initialize = useCallback(async () => {
    try {
      let accessToken = sessionStorage.getItem(STORAGE_KEY);
      let returnUrl = null;

      await fetch(`${process.env.REACT_APP_HOST_API}/api/auth/session`, {
        credentials: 'include',
      })
        .then((response) => {
          if (response.ok) {
            // Check if the status code is 200-299
            return response.json(); // Parses the body of the response as JSON
          }
          throw new Error('Network response was not ok.');
        })
        .then((data) => {
          console.log(data);
          if (data.returnUrl) {
            returnUrl = data.returnUrl;
          }
          accessToken = data.accessToken;
        })
        .catch((error) => {
          console.error('There has been a problem with your fetch operation:', error);
        });

      if (accessToken) {
        setSession(accessToken);

        const response = await axios.get(endpoints.auth.me, {
          withCredentials: true, // Include cookies with the request
        });

        const { user } = response.data;

        dispatch({
          type: 'INITIAL',
          payload: {
            user,
          },
        });
        // http://localhost:3030/profile/register
        if (returnUrl !== null) {
          router.replace(returnUrl);
        }
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    const data = {
      email,
      password,
      from: 'jwt',
    };

    const response = await axios.post(endpoints.auth.login, data);

    const { accessToken, user } = response.data;

    setSession(accessToken);

    user.from = 'jwt';

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        from: 'jwt',
      },
    });
  }, []);

  const googleLogin = useCallback(async (googleData) => {
    try {
      const { credential, clientId, select_by } = googleData;
      console.log(credential, clientId, select_by);
      // Exchange the token from Google for user data from your backend
      const response = await axios.post(endpoints.auth.googleLogin, {
        token: credential, // Send ID token to your backend
        clientId,
      });

      console.log(response.data);

      const { accessToken, user } = response.data;
      console.log(accessToken, user);

      setSession(accessToken);
      user.from = 'google';

      dispatch({
        type: 'LOGIN',
        payload: {
          user,
          from: 'google',
        },
      });
    } catch (error) {
      console.error(error);
      // Handle error
    }
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const data = {
      email,
      password,
      firstName,
      lastName,
    };

    const response = await axios.post(endpoints.auth.register, data);

    const { accessToken, user } = response.data;

    sessionStorage.setItem(STORAGE_KEY, accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
        from: 'jwt',
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    console.log('logout');
    const response = await fetch(`${process.env.REACT_APP_HOST_API}/api/auth/logout`, {
      method: 'POST',
      credentials: 'include',
    });
    if (response.ok) {
      setSession(null);
      dispatch({
        type: 'LOGOUT',
      });
    } else {
      console.error('Logout failed');
    }
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      googleLogin,
      register,
      logout,
    }),
    [login, googleLogin, logout, register, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
